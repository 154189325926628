import React, {useEffect, useRef, useState} from 'react'
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'
import FadeIn from 'react-fade-in';
import scrollTo from 'gatsby-plugin-smoothscroll'

import { Tween, Reveal } from 'react-gsap';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
    AccordionItemState,
} from 'react-accessible-accordion';

import WheelMan from '../images/logos/badges/wheelman.svg'
import HiSpeedRez from '../images/logos/badges/hispeedrez.svg'
import TreadLightly from '../images/logos/badges/treadlightly.svg'
import Spotter from '../images/logos/badges/spotter.svg'
import Elevated from '../images/logos/badges/elevated.svg'
import Convoy from '../images/logos/badges/convoy.svg'
import SquadUp from '../images/logos/badges/squadup.svg'
import LongView from '../images/logos/badges/longview.svg'
import LockBadge from '../images/logos/badges/lock-badge.svg'

import achievHlogo from '../images/logos/achiev-red.svg'
import meetTheTeam from '../images/buttons/meet-the-team-white.svg'
import backToTop from '../images/buttons/back-to-top-white.svg'

import userScump from '../videos/team/scump_full.mp4'
import userScumpMobile from '../videos/team/scump_mobile.mp4'
import userIdoWork from '../videos/team/idowork_full.mp4'
import userIdoWorkMobile from '../videos/team/idowork_mobile.mp4'
import nadia from '../videos/team/nadia_full.mp4'
import nadiaMobile from '../videos/team/nadia_mobile.mp4'
import wildcat from '../videos/team/wildcat_full.mp4'
import wildcatMobile from '../videos/team/wildcat_mobile.mp4'
import huskerrsMobile from '../videos/team/huskerrs_mobile.mp4'
import huskerrs from '../videos/team/huskerrs_full.mp4'
import aja from '../videos/team/aja_full.mp4'
import ajaMobile from '../videos/team/aja_mobile.mp4'
import max from '../videos/team/max_full.mp4'
import maxMobile from '../videos/team/max_mobile.mp4'

import teammateScump from '../images/teams/teammate-scump.png'
import teammateHuskerrs from '../images/teams/teammate-huskerrs.png'
import teammateIdowrk from '../images/teams/teammate-doallwrk.png'
import teammateWildcat from '../images/teams/teammate-wildcat.png'
import teammateNadia from '../images/teams/teammate-nadia.png'


export default function Team() {
    
    const ref = useRef()
    const heroRef = React.useRef(null); 
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        console.log(heroRef.current.offsetTop)
    }, [])

    const [openFirst, setOpenFirst] = React.useState(false);

    useEffect( () => {
        if ( ref.current ) {
            console.log( ref.current.splide.index + '/' + ref.current.splide.length );
        }
    } );
    const currentSlide = ref.current ? ref.current.splide.index + 1 : '1'
    const totalSlides = ref.current ? ref.current.splide.length : '5'    

    const handleThumbs = (id) => {
        if ( ref.current ) {
            ref.current.splide.go(id);
        }
    };

    const teamPlayers = [
        {
            userNumber: '1',
            name: "Scump",
            teamStatus: "Commander",
            userBio: "Seth \"Scump\" Abner is considered one of the best professional Call of Duty® players in the world. As commander of Team AchiEV, he guides this squad to victory with the help of two Team trainers — and one GMC HUMMER EV.",
            avatarImg: teammateScump, 
            userVideoDesktop: userScump,
            userVideoMobile: userScumpMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: Elevated
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: Convoy
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: SquadUp
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LongView
                }
            ]
        },
        {
            userNumber: '2',
            name: "BasicallyIDoWrk",
            teamStatus: "Team Achiev",
            userBio: "Marcel Cunningham  better known online as BasicallyIDoWrk, is an American YouTuber and video game commentator from Orlando, Florida. He's known for his quick wit, and plans to bring it with him to every GMC HUMMER EV challenge.",
            avatarImg: teammateIdowrk, 
            userVideoDesktop: userIdoWork,
            userVideoMobile: userIdoWorkMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LockBadge
                }
            ]
        },
        {
            userNumber: '3',
            name: "HusKerrs",
            teamStatus: "Team Achiev",
            userBio: "Jordan Thomas is a streamer, creator and Call of Duty® powerhouse. He's known for his high-level skills and quick reflexes, as well as engaging with chat during his matches. He brings this communication and other talents to Team AchiEV.",
            avatarImg: teammateHuskerrs, 
            userVideoDesktop: huskerrs,
            userVideoMobile: huskerrsMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LockBadge
                }
            ]
        },
        {
            userNumber: '4',
            name: "Wildcat",
            teamStatus: "Team Achiev",
            userBio: "Tyler Wine is an American YouTube gaming commentator and gamer who started his creator career in 2011— and has amassed a huge following since. He's known for his sharp skills and an even sharper tongue. We'll see which he uses most during these challenges.",
            avatarImg: teammateWildcat, 
            userVideoDesktop: wildcat,
            userVideoMobile: wildcatMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LockBadge
                }
            ]
        },
        {
            userNumber: '5',
            name: "Nadia",
            teamStatus: "Team Achiev",
            userBio: "Nadia Amine is a Twitch streamer and Internet personality, known for her content on Call of Duty®, and for being one of the fastest growing female content creators around. Let's see if she's one of the fastest operators in the GMC HUMMER EV challenges.",
            avatarImg: teammateNadia, 
            userVideoDesktop: nadia,
            userVideoMobile: nadiaMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: LockBadge
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LockBadge
                }
            ]
        },
        {
            userNumber: '6',
            name: "A'Ja Wilson",
            teamStatus: "Team Trainer",
            userBio: "As 2022 WNBA Champion, 2022 MVP and 2022 Defensive Player of The Year, A'Ja dominates on the court. Out here in the desert, she does too. As a Team trainer, A'Ja will get this squad in shape.",
            avatarImg: LockBadge, 
            userVideoDesktop: aja,
            userVideoMobile: ajaMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: Elevated
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: Convoy
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: SquadUp
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LongView
                }
            ]
        },
        {
            userNumber: '7',
            name: "Max Holloway",
            teamStatus: "Team Trainer",
            userBio: "UFC Champion and Call of Duty® enthusiast will teach the team that “If you want to be the best, you gotta beat the best. And the best is blessed.” Team AchiEV is going to learn, first hand, what that means out here in the desert.",
            avatarImg: LockBadge, 
            userVideoDesktop: max,
            userVideoMobile: maxMobile,
            badgeEarned: [
                {
                    badgeName: "WHEEL MAN",
                    badgeImage: WheelMan
                },
                {
                    badgeName: "TREAD LIGHTLY",
                    badgeImage: TreadLightly
                },
                {
                    badgeName: "HI-SPEED RES",
                    badgeImage: HiSpeedRez
                },
                {
                    badgeName: "SPOTTER",
                    badgeImage: Spotter
                },
                {
                    badgeName: "ELEVATED",
                    badgeImage: Elevated
                },
                {
                    badgeName: "CONVOY!",
                    badgeImage: Convoy
                },
                {
                    badgeName: "SQUAD UP",
                    badgeImage: SquadUp
                },
                {
                    badgeName: "LONGVIEW",
                    badgeImage: LongView
                }
            ]
        },
    ];

      
  return (
    <>
    <Reveal repeat trigger={<div />}>
    <Tween from={{ opacity: 0 }} duration={3}>
            <div ref={heroRef} id="team" className="">
                <div className="relative h-screen bg-achiev-team-mobile bg-center md:bg-achiev-team bg-cover py-20">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-0 lg:gap-4 h-full p-5 pb-0 lg:px-5 lg:pt-20">
                        <div className="hidden md:block">
                        </div>
                        <div className="mx-auto">
                        <FadeIn transitionDuration={1000}>
                            <img src={achievHlogo} className="w-72 lg:w-[930px] mx-auto" />
                        </FadeIn>
                        <FadeIn delay={800} transitionDuration={1000} className="mx-auto">

                            <p className="text-lg my-10 font-Inter text-center">Meet the four challengers behind the wheel of the GMC HUMMER EV.</p>
                        </FadeIn>
                        </div>
                        <div className="relative w-full">
                            <button type="button" className="absolute bottom-5 md:bottom-5 right-0 md:top-auto"
                                onClick={() => setOpenFirst(true)}>
                                <img src={meetTheTeam} className="w-80 inline-block float-right" />
                            </button>
                        </div>
                    </div>
                <div className="h-20 lg:h-40 bg-gradient-to-b from-black absolute w-full top-0" />
                <div className="h-20 lg:h-30 bg-gradient-to-t from-black absolute w-full bottom-0" />
                </div>  
            </div>
    </Tween>
    </Reveal>

    <Modal 
    open={openFirst} 
    onClose={() => {
        setOpenFirst(false)
        scrollTo(`#team`)
    }}
    center 
    classNames={{
        overlay: '',
        closeButton: '!hidden',
        modal: '!max-w-none !m-0 !p-0 !bg-transparent !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto',
    }}
    >
        {teamPlayers.map((item, index) => {
            <h1>{item.name}</h1>
        })}
    <div className="relative w-full">
        <div className="h-full bg-gradient-to-b from-black absolute w-full top-0">
            <div className="flex h-screen relative flex flex-col w-full pointer-events-auto text-black bg-black">
                
                <button 
                    type="button" 
                    className="absolute top-0 p-5 md:mx-5 left-0 w-full bg-black z-20 md:bg-transparent lg:top-10 lg:left-0 lg:p-0 md:w-80" 
                    onClick={() => {
                        setOpenFirst(false)
                        scrollTo(`#team`)
                    }}
                ><img src={backToTop} className="w-80" /></button>
           
                <Splide 
                    ref={ref}
                    options={ {
                        perPage: 1,
                        gap   : '0.5rem',
                        type  : 'fade',
                        rewind : true,
                        speed : 1000,
                        drag : false,
                        pagination: false,
                        isNavigation: true,
                        // start           : 2,
                        classes: {
                            page      : 'splide__pagination__page !bg-red-600 [&:not(.is-active)]:!bg-gray-400 !my-5 !scale-150 [&:not(.is-active)]:!scale-105'
                            // pagination: 'splide__pagination px-10 bottom-0 absolute left-0 right-0 grid grid-cols-12 gap-4 text-center',
                            // arrows    : 'splide__arrows !absolute lg:!bottom-44 !bottom-52 lg:!w-40 !w-52 lg:!left-36 !left-1/2 !-translate-x-1/2 !-translate-y-1/2 z-40',
                            // arrow     : 'splide__arrow !opacity-100 lg:!w-14 lg:!h-14 !w-20 !h-20 !rounded-none !bg-transparent',
                            // prev      : 'splide__arrow--prev !bg-prev-arrow !bg-no-repeat !bg-contain lg:!w-14 !w-20',
                            // next      : 'splide__arrow--next !bg-next-arrow !bg-no-repeat !bg-contain lg:!w-14 !w-20',
                        },    
                    } }
                    aria-label="latest-projects"
                >
                
                <div className="splide__arrows absolute bottom-[12%] lg:bottom-40 w-40 w-52 lg:!left-36 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50">
                    <button 
                        className="splide__arrow opacity-100 md:!w-14 md:!h-14 !w-20 !h-20 !rounded-none !bg-transparent splide__arrow--prev !bg-prev-arrow !left-0 !bg-no-repeat !bg-contain lg:!w-14 !w-20" 
                        onClick={() => {
                            setVisible(!visible)
                            handleThumbs()
                        }} 
                    />
                    <div className="w-10 h-10 text-center text-white mx-auto py-2">{currentSlide} / {totalSlides}</div>
                    <button 
                        className="splide__arrow opacity-100 md:!w-14 md:!h-14 !w-20 !h-20 !rounded-none !bg-transparent splide__arrow--next !right-0 !bg-next-arrow !bg-no-repeat !bg-contain lg:!w-14 !w-20" 
                        onClick={() => {
                            setVisible(!visible)
                            {teamPlayers.map((index) => (
                                handleThumbs(index)
                            ))}
                        }} 
                    />
                </div>
                
                    <div className="h-48 top-30 w-full bg-gradient-to-t from-black absolute bottom-0 z-0" />

                    {teamPlayers.map((item, index) => (
                        <SplideSlide 
                        className="h-screen relative flex items-center overflow-hidden"
                        key={index}>
                            <video 
                                width="100%" 
                                autoPlay 
                                loop 
                                muted 
                                playsInline 
                                controls={false}
                                className="hidden md:block absolute w-auto min-w-full min-h-full object-cover"
                            >
                            <source
                                src={item.userVideoDesktop}
                                type="video/mp4"
                            />
                            </video>
                            <video 
                                width="100%" 
                                autoPlay 
                                loop 
                                muted 
                                playsInline 
                                controls={false}
                                className="lg:hidden absolute top-20 md:top-0 w-auto min-w-full min-h-full object-cover"
                            >
                            <source
                                src={item.userVideoMobile}
                                type="video/mp4"
                            />
                            </video>
                            <div className="h-screen w-screen bg-gradient-to-t from-black opacity-70 absolute top-0" />
                            <div className="h-3/6 lg:h-60 w-full bg-gradient-to-t from-black absolute bottom-0" />
                            <div className="absolute grid grid-col-1 lg:grid-cols-3 lg:gap-4 w-full z-40  bottom-[27%] lg:bottom-0">
                                <div className="">        
                                    <FadeIn transitionDuration={1000} className="max-w-2xl mx-auto mb-2 md:mb-3">
                                        <div className="inline-block lg:align-middle lg:flex lg:h-screen z-20">
                                            <div className="m-auto text-white lg:text-left px-5 lg:px-10">
                                                <h3 className="mb-2 text-xl md:text-3xl mb-3 md:mb-5 font-StratumBold uppercase">{item.teamStatus} – {item.name}</h3>
                                                <p className="text-sm md:text-base font-Inter">{item.userBio}</p>
                                            </div>
                                        </div>
                                    </FadeIn>
                                </div>
                                <div className=""></div>
                                <div className="">
                                    <div className="max-w-2xl mx-auto text-left inline-block lg:absolute bottom-40 lg:bottom-0 lg:relative lg:align-middle px-0 w-full lg:px-0 flex lg:h-screen z-20">
                                        <div className="w-full lg:m-auto text-white p-0">
                                            <div className="">
                                            <h4 className="font-StratumBold text-md uppercase pl-5 text-base lg:text-lg mb-2 lg:mb-5 lg:pb-5">Achievements Earned</h4>
                                            
                                            <ul className="">
                                                <FadeIn transitionDuration={1000} className="px-5 list-inside grid grid-cols-8 lg:grid-cols-4 gap-4">
                                                    {item.badgeEarned && item.badgeEarned.map(badge => (
                                                        <li className="text-center" key={badge.badgeName}>
                                                            <img src={badge.badgeImage} alt={badge.badgeName} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">{badge.badgeName}</h5>
                                                        </li>
                                                    ))}
                                                </FadeIn>
                                            </ul>
                                                
                                           
                                                {/* <ul className="">
                                                    <FadeIn transitionDuration={1000} className="px-5 list-inside grid grid-cols-8 lg:grid-cols-4 gap-4">
                                                        <li className="text-center">
                                                            <img src={WheelMan} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">WHEEL MAN</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={TreadLightly} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">TREAD LIGHTLY</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={HiSpeedRez} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">HI-SPEED RES</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={Spotter} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">SPOTTER</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={Elevated} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">ELEVATED</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={Convoy} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">CONVOY!</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={SquadUp} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">SQUAD UP</h5>
                                                        </li>
                                                        <li className="text-center">
                                                            <img src={LongView} className="lg:w-20 m-auto" />
                                                            <h5 className="hidden lg:inline text-sm uppercase">LONGVIEW</h5>
                                                        </li>
                                                    </FadeIn>
                                                </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SplideSlide>
                     ))}
                </Splide>

                {/* <div className="hidden lg:block absolute bottom-6 z-40 w-full h-28">
                    <div className="h-14 w-1/3 md:bg-gradient-radial-to-tr from-black absolute w-full bottom-0 z-50" />
                    <div className="h-14 w-1/3 md:bg-gradient-radial-to-tl from-black absolute w-full bottom-0 right-0 z-50" />
                        <Splide       
                            options={{ 
                                perPage         : 5,
                                arrows          : false,
                                drag            : false,
                                rewind          : false,
                                pagination      : false,
                                cover           : true,
                                fixedWidth      : 140,
                                fixedHeight     : 90,
                                focus           : 'center',
                                isNavigation    : false,
                                draggable       : false,
                                swipeToSlide    : false,
                                touchMove       : false,   
                                focusOnSelect   : true,
                                start           : 2,
                                classes         : {},
                                // gap             : '6.3rem',
                            }}  
                            className="h-28 bg-pagin bg-repeat-x bg-bottom bg-[length:200px] bg-bottom"
                        >
                            {teamPlayers.map((item, index) => (
                            <SplideSlide  
                            onClick={() => 
                                {
                                    setVisible(!visible);
                                    handleThumbs(index);
                                }} 
                                key={index}>
                                <div className="w-full">
                                    <img src={item.avatarImg} alt="" className="w-14 h-14 mx-auto mb-1 hover:cursor-pointer" />
                                    <div className="text-sm text-center text-white font-StratumBold uppercase">{item.name}</div>
                                </div>
                            </SplideSlide>
                            ))}
                        </Splide>

                    <div className="border-b-2 border-[#585a5e] w-full absolute bottom-0 z-0" />
                </div> */}

            </div>
        </div>
    </div>
    </Modal>
    </>
  )
}
