import * as React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import ogImageMain from '../images/team_achiev_banner_1200x628.jpg'
// import { Splide, SplideSlide } from '@splidejs/react-splide'
// import '../../node_modules/@splidejs/splide/dist/css/splide.min.css'

// import { Controller, Scene } from 'react-scrollmagic';
import { Timeline, ScrollTrigger } from "react-gsap";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from '../components/hero'
import Team from '../components/team'
import HummerEV from '../components/hummerev'
import Contracts from '../components/contracts'
import Footer from '../components/footer'

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const siteDescription = data.site.siteMetadata.description
  const siteUrl = data.site.siteMetadata.siteUrl
  
  // const posts = data.webiny.listHummerEvPosts.data

  // if (posts.length === 0) {
  //   return (
  //     <Layout location={location} title={siteTitle}>
  //       <Seo title="All posts" />
  //       <p>
  //         No blog posts found. Add markdown posts to "content/blog" (or the
  //         directory you specified for the "gatsby-source-filesystem" plugin in
  //         gatsby-config.js).
  //       </p>
  //     </Layout>
  //   )
  // }

  return (
    <Layout location={location} title={siteTitle} className="bg-black">
      <Seo title="Team Achiev" />
      <Helmet>
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={siteTitle} />
        <meta property='og:description' content={siteDescription} />
        <meta property='og:url' content='https://www.teamachiev.com/' />
        <meta property="og:image" content={`${siteUrl}` + `${ogImageMain}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@teamachiev" />
        <meta name="twitter:creator" content="@teamachiev" />
        <meta property="og:url" content={siteUrl} />
        <meta property="og:title" content={siteTitle} />
        <meta property="og:description" content={siteDescription} />
        <meta property="og:image" content={`${siteUrl}` + `${ogImageMain}`} />
      </Helmet>
      <div>

          <ScrollTrigger>
            <Timeline duration={800} pin={true}>
              <Hero />
            </Timeline>
            <Timeline duration={800} pin={true}>
              <Team />
            </Timeline>
            <Timeline duration={800} pin={true}>
              <HummerEV />
            </Timeline>
          </ScrollTrigger>
          <Contracts />
          <Footer />
        {/* <Splide 
          options={ {
              perPage: 1,
              gap   : '0.5rem',
          } }
          aria-label="latest-projects"
          className="snap-start w-screen flex items-center justify-center"
          >
          {posts.map(post => {
          return (
            <SplideSlide className="py-2 flex h-screen"> 
              <div key={post.slug} className="rounded p-5 rounded-lg shadow-lg mb-5 lg:mb-0">
                  <article
                      className="post-list-item space-y-4"
                      itemScope
                      itemType="http://schema.org/Article"
                  >
                  <Link to={post.slug} itemProp="url">
                      <div className="aspect-w-3 aspect-h-2">
                      <img src={post.profileImage} className="featureImage rounded-lg object-cover shadow-lg" />
                      </div>
                      <h2 className="my-3 text-2xl font-StratumBold uppercase">
                          <span itemProp="headline text-lg uppercase ">{post.title}</span>
                      </h2>
                      <span className="text-lg uppercase font-StratumRegular">Team: {post.team}</span>
                      <p className="text-sm font-Inter">{post.description}</p>
                  </Link>
                  </article>
              </div>
            </SplideSlide>
          )
          })}
        </Splide> */}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    webiny {
      listHummerEvPosts(sort:createdOn_DESC) {
        data {
          id
          title
          team
          slug
          description
          profileImage
          socialYouTube
          socialTwitter
          socialInstagram
          socialFacebook
          socialTwitch
          socialTikTok
        }
      }
    } 
  }
`
