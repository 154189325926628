import React from 'react'


export default function Footer() {
  const heroRef = React.useRef(null); 


  return (
    <>
      <footer className="text-xs p-5 font-Inter text-left">
        <p className="mb-3 font-bold">Disclosures: </p>
        <p className="mb-3">¹ <strong className="font-bold">CrabWalk™</strong> Limited to low speeds. Read the vehicle Owner's Manual for important feature limitations and information. Standard on Edition 1, EV 3X and EV 2X. Available Ultravision with Underbody Cameras: Read the vehicle Owner's Manual for important feature limitations and information. Some camera views require available accessory camera and installation. Not compatible with all trailers. See dealer for details. Underbody cameras are standard on Edition 1 Pickup, available on Edition 1 SUV, and available on EV3X and EV2X SUV and Pickup. Available Watts to Freedom: Read the vehicle Owner's Manual for important feature limitations and information. Standard on Edition 1 and available on EV3X.</p>
        <p className="mb-3">² <strong>Available Ultravision with Underbody Cameras:</strong> Read the vehicle Owner's Manual for important feature limitations and information. Some camera views require available accessory camera and installation. Not compatible with all trailers. See dealer for details. Underbody cameras are standard on Edition 1 Pickup, available on Edition 1 SUV, and available on EV3X and EV2X SUV and Pickup.</p>
        <p className="mb-3">³ <strong>Available Watts to Freedom:</strong> Read the vehicle Owner's Manual for important feature limitations and information. Standard on Edition 1 and available on EV3X.</p>
        <p>© {new Date().getFullYear()} 3BLACKDOT, LLC | © {new Date().getFullYear()} Activision Publishing, Inc. ACTIVISION, CALL OF DUTY, and CALL OF DUTY WARZONE are trademarks of Activision Publishing, Inc. | © {new Date().getFullYear()} GMC</p>
      </footer>
    </>
  )
}
