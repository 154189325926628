import React, {useEffect, useRef} from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll'
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import learnMore from '../images/buttons/learn-more-white.svg'
import experienceTheHummerEv from '../images/buttons/experience-the-hummer-ev.svg'
import backToTop from '../images/buttons/back-to-top-white.svg'
import { Tween, Reveal } from 'react-gsap'

import hummerEVpromoDesktopCover from '../images/hummer-driving-desktop.jpg'
import hummerEVpromoMobileCover from '../images/hummer-driving-mobile.jpg'
import gmcHummerEv from '../images/logos/gmc-hummerev.svg'
import gmcHummerEvWhite from '../images/logos/gmc-hummerev-wht.svg'
import hummerEVpromoMobile from '../videos/loop1-mobile.mp4'
import hummerEVpromoDesktop from '../videos/loop1-desktop.mp4'
import hummerEVpromo2Desktop from '../videos/loop2_desktop.mp4'
import hummerEVpromo2Mobile from '../videos/loop2_mobile.mp4'

export default function HummerEV() {
  const heroRef = React.useRef(null); 

  useEffect(() => {
    console.log(heroRef.current.offsetTop)
  }, [])

  const [openFirst, setOpenFirst] = React.useState(false);

  return (
    <>
    <Reveal repeat trigger={<div />}>
    <Tween from={{ opacity: 0 }} duration={3}>

    <div ref={heroRef} id="meetHummerEV" className="snap-start w-screen flex items-center justify-center" />
    
    <div className="h-screen relative flex items-center overflow-hidden">
        <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            poster={hummerEVpromoMobileCover} 
            id="heroVideo" 
            className="block lg:hidden absolute top-0 w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromoMobile}
            type="video/mp4"
        />
        </video>
        <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            poster={hummerEVpromoDesktopCover} 
            id="heroVideo" 
            className="hidden lg:block absolute top-0 w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromoDesktop}
            type="video/mp4"
        />
        </video>


          <div className="grid grid-cols-1 w-full lg:grid-cols-3 gap-0 h-full bg-left bg-no-repeat p-0 lg:px-5 md:py-20 md:absolute z-0 lg:left-5">
          <div className="hidden md:block"></div>
          <div className="mx-auto m-auto xl:m-0 xl:mt-20 xl:px-16 xl:pt-20">
            <div className="">
                <img src={gmcHummerEv} className="w-72 md:w-96 mx-auto" />
                <p className="mx-10 md:mx-0 my-7 font-Inter text-center text-black text-lg">Seek out the world's first all-electric supertruck in Al Mazrah.</p>
            </div>
          </div>
          <div className="relative w-full">
            <button 
              type="button" 
              className="absolute bottom-24 md:bottom-5 right-5 md:top-auto"
              onClick={() => setOpenFirst(true)}>
                <img src={learnMore} className="w-80 inline-block float-right" />
            </button>


          </div>
        </div>
            

        <div className="h-20 lg:h-36 bg-gradient-to-b from-black absolute w-full top-0 z-0" />
        <div className="h-20 lg:h-30 bg-gradient-to-t from-black absolute w-full bottom-0 z-0" />

    </div>
    
    </Tween>
    </Reveal>

    <Modal 
    open={openFirst} 
    onClose={() => {
        setOpenFirst(false)
        scrollTo(`#meetHummerEV`)
    }}
    center 
    classNames={{
        overlay: '',
        closeButton: '!hidden',
        modal: '!max-w-none !m-0 !p-0 !bg-transparent !fixed top-0 left-0 hidden !w-full h-full outline-none overflow-x-hidden overflow-y-auto',
    }}
    >
      <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            id="heroVideo" 
            className="block lg:hidden absolute top-0 w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromo2Mobile}
            type="video/mp4"
        />
        </video>
        <video 
            width="100%" 
            autoPlay 
            loop 
            muted 
            playsInline 
            poster={hummerEVpromoDesktopCover} 
            id="heroVideo" 
            className="hidden lg:block absolute top-0 w-auto min-w-full min-h-full object-cover"
        >
        <source
            src={hummerEVpromo2Desktop}
            type="video/mp4"
        />
        </video>

      <div className="relative w-full">
        <div className="relative w-full h-full max-w-none m-0 pointer-events-none bg-bottom lg:bg-center bg-cover absolute w-full top-0">
        <div className="h-20 lg:h-30 bg-gradient-to-b from-black absolute w-full top-0 z-0" />
          <div className="flex h-screen relative flex flex-col w-full pointer-events-auto text-white text-left">
          <div className="overflow-hidden relative h-full bg-center bg-cover pt-0 lg:pt-0">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-0 lg:gap-4 h-full bg-left bg-no-repeat p-0 lg:px-5 lg:py-20 absolute z-0 lg:left-5">
              
              <div className="md:col-span-1 m-auto mt-20 p-5 pb-0">
                <img src={gmcHummerEvWhite} className="w-56 mx-auto" />
                <p className="text-sm mx-0 my-7 font-Inter text-center text-white inline-block md:hidden md:text-black">GMC HUMMER EV Pickup dominates terrain with revolutionary features and capabilities.</p>
              </div>
              <div className="md:col-span-2 md:m-auto text-center md:text-left px-5 md:pb-14 lg:px-0">
                <button 
                  type="button" 
                  className="absolute top-0 p-5 left-0 w-full bg-black z-20 md:bg-transparent lg:top-10 lg:left-0 lg:p-0 md:w-80" 
                  onClick={() => {
                      setOpenFirst(false)
                      scrollTo(`#meetHummerEV`)
                  }}
                >
                  <img src={backToTop} className="w-80" />
                </button>
                <h3 className="text-base text-xl text-white text-left hidden md:inline-block font-StratumBold text-md py-10 lg:px-5 lg:mb-5 lg:py-0 uppercase">GMC HUMMER EV Pickup dominates terrain with revolutionary features and capabilities.</h3>
                  <div className="text-white">
                    <Accordion
                      allowMultipleExpanded={false}
                      allowZeroExpanded
                      className="inline-block w-full sm:hidden"
                    >
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                <span className="text-white text-lg">Infinity Roof</span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p className="text-sm font-Inter text-white text-left p-0">Let the world in by removing the four Transparent Sky Panels.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                <span className="text-white text-lg">Crabwalk<span className="text-xxs absolute pl-2 h-[10px]">1</span></span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p className="text-sm font-Inter text-white text-left p-0">Utilize available 4-Wheel Steer to drive diagonally at low speeds.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                <span className="text-white text-lg">Ultravision<span className="text-xxs absolute pl-2 h-[10px]">2</span></span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p className="text-sm font-Inter text-white text-left p-0">Use real-time views of the terrain to pick the best line along the trail.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                <span className="text-white text-lg">Watts to Freedom<span className="text-xxs absolute pl-2 h-[10px]">3</span></span>
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p className="text-sm font-Inter text-white text-left p-0">Experience unleashing the HUMMER EV's propulsion system.</p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                    <ul className="hidden sm:grid grid-cols-2 gap-4 px-5">
                        <li className="mb-5">
                          <h4 className="font-StratumBold uppercase text-md">Infinity Roof</h4>
                          <p className="text-md font-Inter">Let the world in by removing the four Transparent Sky Panels.</p>
                        </li>
                        <li className="mb-5">
                          <h4 className="font-StratumBold uppercase text-md">Crabwalk<span className="text-xxs absolute pl-2 h-[10px]">1</span></h4>
                          <p className="text-md font-Inter">Utilize available 4-Wheel Steer to drive diagonally at low speeds.</p>
                        </li>
                        <li className="mb-5">
                          <h4 className="font-StratumBold uppercase text-md">Ultravision<span className="text-xxs absolute pl-1 h-[10px]">2</span></h4>
                          <p className="text-md font-Inter">Use real-time views of the terrain to pick the best line along the trail.</p>
                        </li>
                        <li className="mb-5">
                          <h4 className="font-StratumBold uppercase text-md">Watts to Freedom<span className="text-xxs absolute pl-1 h-[10px]">3</span></h4>
                          <p className="text-md font-Inter">Experience unleashing the HUMMER EV's propulsion system.</p>
                        </li>
                    </ul>

                    <div className="block md:hidden mt-10">
                    <a href="https://www.gmc.com/electric/hummer-ev/pickup-trucks" target="_blank"  className="float-right">
                      <button 
                        type="button" 
                        className="px-5 lg:px-0 left-0 right-0 md:bottom-20 md:right-5"
                      >
                        <img src={experienceTheHummerEv} className="w-80 inline-block float-right" />
                      </button>
                    </a>
                    </div>
                  </div>
              </div>
              <div className="hidden md:block">
              <a href="https://www.gmc.com/electric/hummer-ev/pickup-trucks" target="_blank"  className="">
                <button 
                  type="button" 
                  className="absolute bottom-20 right-5 md:top-auto"
                >
                  <img src={experienceTheHummerEv} className="w-80 inline-block float-right" />
                </button>
              </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Modal>
    </>
  )
}
